<template>
  <div class="hello">
    <section class="banner home">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full">
        <div class="flex flex-col justify-center items-center p-5 h-screen">
          <div class="max-w-md text-center w-full">
            <h2 class="text-2xl text-center font-semibold">Contact Us</h2>
            <input
              class="appearance-none border border-black rounded-3xl w-full p-3 px-3 mt-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="name"
              v-model="name"
              v-validate="'required'"
              placeholder="Name" />
            <span class="block text-red-500 italic text-left">{{ errors.first('name') }}</span>
            <input
              class="appearance-none border border-black rounded-3xl w-full p-3 px-3 mt-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="email"
              v-model="email"
              v-validate="'required|email'"
              placeholder="Email address" />
            <span class="block text-red-500 italic text-left">{{ errors.first('email') }}</span>
            <textarea
              class="password appearance-none border border-black rounded-3xl w-full p-3 px-3 mt-6 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
              name="message"
              v-model="message"
              v-validate="'required'"
              placeholder="Message"></textarea>
            <span class="block text-red-500 italic text-left">{{ errors.first('message') }}</span>

            <button
              :disabled="errors.items.length > 0"
              :class="{ 'opacity-50 cursor-not-allowed': errors.items.length > 0 }"
              class="sign-in bg-blue-500 hover:bg-blue-700 text-white py-3 px-14 rounded-full font-normal mt-5"
              @click.prevent="sendMessage">
              Submit
            </button>
            <div class="hr mt-2">
              <span>or</span>
            </div>
            <div class="social-m">
              <div class="grid grid-cols-2 gap-4 h-full px-10">
                <button class="border border-black text-black py-3 rounded-full font-normal mt-3" @click.prevent="$emit('google-sign-in')">Call Us</button>
                <button class="border border-black text-black py-2 rounded-full font-normal mt-3" @click.prevent="$emit('facebook-sign-in')">Email Us</button>
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/images/support-banner.png" style="height: 100vh" class="object-cover object-top hidden lg:block" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  data() {
    return {
      name: null,
      email: null,
      message: null,
    };
  },
  methods: {
    sendMessage() {},
  },
};
</script>
